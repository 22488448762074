import * as React from 'react';
import { Seo } from '../../components/Seo'
import { Layout } from '../../components/Layout';

const VrToursTemplate = ({ data }) => {
   

    return (
        <Layout>
            <Seo title="Vr Tours" description="" />
            <section>
                Vr Tours
            </section>
        </Layout>
    )
}


export default VrToursTemplate;